import { AfterViewInit, Component, ElementRef, Renderer2, signal, WritableSignal } from '@angular/core';
import { ColorExtractorService } from '../color-extractor.service';

@Component({
    selector: 'otb-skeleton-loader-header',
    templateUrl: './skeleton-loader-header.component.html',
    styleUrls: ['./skeleton-loader-header.component.scss']
})
export class SkeletonLoaderHeaderComponent {
    backgroundColor: WritableSignal<string>;
    constructor(private colorExtractorService: ColorExtractorService) {
        this.backgroundColor = this.colorExtractorService.headerColor;
    }
}
