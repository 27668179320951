<div
    aria-labelledby="progress-bar-label"
    class="d-flex justify-content-between progress-bar-container"
    *otbShowInEnv="['default', 'justice', 'sh', 'education-check', 'finance', 'hmdj']"
>
    <div class="progress-bar" role="progressbar" [attr.aria-valuenow]="currentStep" [attr.aria-valuemax]="totalSteps">
        <div class="progress-bar--inner" [style.width]="progress"></div>
    </div>
    <span
        class="justify-content-end"
        id="progress-bar-label"
        [attr.aria-label]="
            ('dynamische_frontend_texte.landing.fortschritt' | translate) + ' ' + currentStep + ' von ' + totalSteps
        "
    >
        <span [innerHTML]="'dynamische_frontend_texte.landing.fortschritt' | translate | safeStyle"></span>
        {{ currentStep }}/{{ totalSteps }}
    </span>
</div>
