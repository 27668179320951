<div class="row">
    <otb-inner-breadcrumb
        *otbShowInEnv="['default', 'sh', 'education-check', 'finance', 'justice', 'hmdj']"
    ></otb-inner-breadcrumb>
    <otb-progress-bar></otb-progress-bar>
</div>
<div class="row">
    <div class="col-12">
        <h2 [innerHTML]="'dynamische_frontend_texte.terminart.ueberschrift' | translate | safeStyle"></h2>
    </div>
    <div class="col-12 mt-5">
        <form id="agency-service-selection" [formGroup]="appointmentTypeFormGroup" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="form-group">
                        <label
                            for="citizen-appointment-type"
                            [innerHTML]="'dynamische_frontend_texte.terminart.label' | translate | star | safeStyle"
                        ></label>
                        <ng-select
                            id="citizen-appointment-type"
                            [items]="appointmentTypeOptions"
                            bindLabel="value"
                            bindValue="name"
                            (change)="onAppointmentTypeSelect($event)"
                            formControlName="type"
                            [clearable]="false"
                            placeholder="Online-Beratung"
                        ></ng-select>
                        <otb-form-error-message
                            [show]="formAppointmentType.invalid && formAppointmentType.dirty"
                        ></otb-form-error-message>
                    </div>
                </div>

                <div class="col-12 mt-4">
                    <button
                        type="submit"
                        class="btn-success btn rounded-0 float-end"
                        accesskey="w"
                        [title]="'dynamische_frontend_texte.landing.button_text' | translate"
                    >
                        {{ 'dynamische_frontend_texte.landing.button_text' | translate }}
                    </button>

                    <a
                        (click)="back()"
                        class="btn-secondary btn pull-left order-md-1 order-2"
                        [title]="'dynamische_frontend_texte.verteilmechanismen.zurueck' | translate"
                        accesskey="z"
                        tabindex="0"
                    >
                        {{ 'dynamische_frontend_texte.verteilmechanismen.zurueck' | translate }}
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>
