<div class="row">
    <otb-inner-breadcrumb
        *otbShowInEnv="['default', 'sh', 'education-check', 'finance', 'justice', 'hmdj']"
    ></otb-inner-breadcrumb>
    <otb-progress-bar></otb-progress-bar>
</div>

<div class="row" *ngIf="hasAppointments; else noTimeSlotsTemplate">
    <div class="col-12">
        <h2 [innerHTML]="'dynamische_frontend_texte.zeit_auswahl.ueberschrift' | translate | safeStyle"></h2>
    </div>
    <div class="col-12 mb-5" *ngIf="!showFormSwitcherTop">
        <div class="changeform__container">
            <a
                (click)="switchToDateFirst($event)"
                href="#"
                class="changeform has--before-arrow"
                title="{{ 'dynamische_frontend_texte.datum_auswahl.suche_fuer_bestimmtes_datum' | translate }}"
                aria-label="Wechsel zur Datums-Ansicht"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.suche_fuer_bestimmtes_datum' | translate | safeStyle
                "
            >
            </a>
        </div>
    </div>
    <div class="col-12 col-sm-6" [class.d-none]="timesLoading">
        <otb-time-pick-selection
            [dateFirst]="false"
            [availableAgencies]="times"
            (timeSelect)="onTimeSelect($event)"
        ></otb-time-pick-selection>
    </div>
    <div class="col-12 col-sm-6 mb-5" *ngIf="timeChosen">
        <div class="upper__container mb-5 mb-sm-2">
            <p
                [innerHTML]="
                    'dynamische_frontend_texte.zeit_auswahl.auswahl'
                        | translate
                        | replaceDateTime: selectedTimeDateFormat
                        | async
                "
            ></p>
        </div>
        <div
            #calendarContainer
            [class.colorblind-active]="colorLegendShow | async"
            [class.d-none]="timesLoading || calendarLoading"
        >
            <input type="hidden" #datepicker />
        </div>
        <div class="loading--text" *ngIf="timeChosen && calendarLoading">
            <otb-loading-animation-dots
                [text]="'dynamische_frontend_texte.lade_animation.kalender'"
            ></otb-loading-animation-dots>
        </div>

        <div class="col-12 mb-5" *ngIf="!showFormSwitcherTop">
            <div class="changeform__container">
                <a
                    (click)="switchToDateFirst($event)"
                    href="#"
                    class="changeform has--before-arrow"
                    title="{{ 'dynamische_frontend_texte.datum_auswahl.suche_fuer_bestimmtes_datum' | translate }}"
                    aria-label="Wechsel zur Datums-Ansicht"
                    [innerHTML]="
                        'dynamische_frontend_texte.datum_auswahl.suche_fuer_bestimmtes_datum' | translate | safeStyle
                    "
                >
                </a>
                <fa-icon class="fa" [icon]="faArrowCircleRight"></fa-icon>
            </div>
        </div>

        <otb-color-legend [class.d-none]="timesLoading || calendarLoading"></otb-color-legend>

        <!-- only relevant for time first -->
        <button
            class="btn btn-success float-end mt-5"
            *ngIf="dateChosen | async"
            [innerHTML]="'dynamische_frontend_texte.zeit_auswahl.weiter_button' | translate | safeStyle"
            (click)="submit()"
        ></button>
    </div>
    <div class="loading--text col-6" [class.d-none]="!timesLoading"></div>
</div>
<div class="row" *ngIf="timesLoading">
    <div class="col-12">
        <otb-loading-animation-dots
            [text]="'dynamische_frontend_texte.lade_animation.zeiten'"
        ></otb-loading-animation-dots>
    </div>
</div>

<ng-template #noTimeSlotsTemplate>
    <div class="row">
        <div class="col-12">
            <p
                class="info__header"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.ueberschrift_keine_termine' | translate | safeStyle
                "
            ></p>
            <p
                class="info__content"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.naechste_freie_termine'
                        | translate
                        | replaceDateTime: nextAppointmentAt
                        | async
                "
            ></p>
        </div>
    </div>
</ng-template>
