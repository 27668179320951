<div class="row">
    <otb-inner-breadcrumb
        *otbShowInEnv="['default', 'sh', 'education-check', 'finance', 'justice', 'hmdj']"
    ></otb-inner-breadcrumb>
    <otb-progress-bar></otb-progress-bar>
</div>

<div class="row">
    <div class="col-12">
        <h2 *ngIf="strategy">{{ strategy.notice }}</h2>
    </div>
</div>

<div class="row" *ngIf="strategyName === 'unknown'">
    <div class="col-12">
        <div class="alert-box">
            <div
                class="alert alert-danger"
                [innerHTML]="
                    'dynamische_frontend_texte.benachrichtigungen.zuordnungsstrategie_nicht_verfuegbar' | translate
                "
            ></div>
        </div>
    </div>

    <div class="col-12">
        <a
            (click)="back()"
            class="btn-secondary btn pull-left order-md-1 order-2"
            [title]="'dynamische_frontend_texte.verteilmechanismen.zurueck' | translate"
            tabindex="0"
            [innerHTML]="'dynamische_frontend_texte.verteilmechanismen.zurueck' | translate | safeStyle"
            accesskey="z"
        >
        </a>
    </div>
</div>

<form [formGroup]="strategyForm" (ngSubmit)="onSubmit()" *ngIf="strategyName === 'name'">
    <div class="row">
        <div class="col-12 col-md-5">
            <label for="nameAddition">Namenszusatz (Optional)</label>
            <input
                class="form-control mb-2"
                id="nameAddition"
                formControlName="nameAddition"
                type="text"
                [placeholder]="namePlaceholder"
            />
            <small class="text-muted">Bitte tragen Sie gegebenenfalls einen Namenszusatz ein.</small>
        </div>
        <div class="col-12 col-md-7">
            <label for="name">{{ lastNameLabel }} *</label>
            <input
                class="form-control mb-2"
                [class.border-danger]="name.invalid && name.dirty"
                id="name"
                formControlName="name"
                type="text"
            />
            <otb-form-error-message [show]="name.invalid && name.dirty && name.errors?.['required']">
            </otb-form-error-message>
            <otb-form-error-message
                [show]="name.invalid && name.dirty && name.errors?.['dinSpecInvalid']"
                [text]="'dynamische_frontend_validatoren.persönliche_daten.din_spec_91379' | translate"
            >
            </otb-form-error-message>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="form-group d-flex justify-content-between flex-md-row flex-column">
                <button
                    type="submit"
                    class="btn-success btn order-md-2 order-1 m-0 mb-3 mb-sm-0"
                    accesskey="w"
                    [title]="'dynamische_frontend_texte.verteilmechanismen.name_button_text' | translate"
                    [innerHTML]="
                        'dynamische_frontend_texte.verteilmechanismen.name_button_text' | translate | safeStyle
                    "
                ></button>

                <a
                    (click)="back()"
                    class="btn-secondary btn pull-left order-md-1 order-2"
                    [title]="'dynamische_frontend_texte.verteilmechanismen.zurueck' | translate"
                    [innerHTML]="'dynamische_frontend_texte.verteilmechanismen.zurueck' | translate | safeStyle"
                    accesskey="z"
                    tabindex="0"
                >
                </a>
            </div>
        </div>
    </div>
</form>

<form [formGroup]="strategyForm" (ngSubmit)="onSubmit()" *ngIf="strategyName === 'list'">
    <div class="row">
        <div class="col-12 col-md-7 mb-3">
            <label
                for="list"
                [innerHTML]="'dynamische_frontend_texte.verteilmechanismen.liste_überschrift' | translate | safeStyle"
            ></label>
            <ng-select
                id="list"
                [items]="listEntries"
                formControlName="list"
                labelForId="Auswahl"
                [clearable]="false"
                accesskey="w"
                [class.border-danger]="strategyForm.controls['list'].invalid && strategyForm.controls['list'].dirty"
                [placeholder]="'dynamische_frontend_texte.verteilmechanismen.liste_platzhalter' | translate"
            >
            </ng-select>

            <otb-form-error-message
                [show]="strategyForm.controls['list'].invalid && strategyForm.controls['list'].dirty"
            ></otb-form-error-message>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="form-group d-flex justify-content-between flex-md-row flex-column">
                <button
                    type="submit"
                    class="btn-success btn order-md-2 order-1 m-0 mb-3 mb-sm-0"
                    accesskey="w"
                    [title]="'dynamische_frontend_texte.verteilmechanismen.name_button_text' | translate"
                    [innerHTML]="
                        'dynamische_frontend_texte.verteilmechanismen.name_button_text' | translate | safeStyle
                    "
                ></button>

                <a
                    (click)="back()"
                    class="btn-secondary btn pull-left order-md-1 order-2"
                    [title]="'dynamische_frontend_texte.verteilmechanismen.zurueck' | translate"
                    [innerHTML]="'dynamische_frontend_texte.verteilmechanismen.zurueck' | translate | safeStyle"
                    accesskey="z"
                    tabindex="0"
                >
                </a>
            </div>
        </div>
    </div>
</form>

<form [formGroup]="strategyForm" (ngSubmit)="onSubmit()" *ngIf="strategyName === 'aktenzeichen'">
    <div class="row">
        <div class="col-sm-4 col-lg-2">
            <label for="Abteilungsnummer">Abteilungsnummer*</label>
            <ng-select
                [items]="departmentNumbers"
                [class.border-danger]="
                    strategyForm.controls['departmentNumbers'].invalid &&
                    strategyForm.controls['departmentNumbers'].dirty
                "
                id="Abteilungsnummer"
                formControlName="departmentNumbers"
                [clearable]="false"
                [(ngModel)]="selectedDepartmentNumber"
                placeholder="Abteilungsnummer"
            ></ng-select>
            <otb-form-error-message
                [show]="
                    strategyForm.controls['departmentNumbers'].invalid &&
                    strategyForm.controls['departmentNumbers'].dirty
                "
            ></otb-form-error-message>
        </div>

        <div class="col-sm-4 col-lg-2">
            <label for="Registerzeichen">Registerzeichen*</label>
            <ng-select
                [items]="registrationMarks"
                [class.border-danger]="
                    strategyForm.controls['registrationMarks'].invalid &&
                    strategyForm.controls['registrationMarks'].dirty
                "
                id="Registerzeichen"
                formControlName="registrationMarks"
                [clearable]="false"
                [(ngModel)]="selectedRegistrationMark"
                placeholder="Registerzeichen"
            ></ng-select>
            <otb-form-error-message
                [show]="
                    strategyForm.controls['registrationMarks'].invalid &&
                    strategyForm.controls['registrationMarks'].dirty
                "
            ></otb-form-error-message>
        </div>

        <div class="col-sm-4 col-lg-2">
            <label for="LaufendeNummer">Laufende Nummer*</label>
            <input
                class="form-control mb-2"
                id="LaufendeNummer"
                formControlName="fileNumber"
                type="text"
                [class.border-danger]="
                    strategyForm.controls['fileNumber'].invalid && strategyForm.controls['fileNumber'].dirty
                "
            />
            <otb-form-error-message
                [show]="strategyForm.controls['fileNumber'].invalid && strategyForm.controls['fileNumber'].dirty"
            ></otb-form-error-message>
        </div>

        <div class="col-sm-4 col-lg-2">
            <label for="Jahresangabe">Jahresangabe*</label>
            <ng-select
                [items]="years"
                [class.border-danger]="strategyForm.controls['years'].invalid && strategyForm.controls['years'].dirty"
                id="Jahresangabe"
                formControlName="years"
                [clearable]="false"
                [(ngModel)]="selectedYear"
                placeholder="Jahresangabe"
            ></ng-select>
            <otb-form-error-message
                [show]="strategyForm.controls['years'].invalid && strategyForm.controls['years'].dirty"
            ></otb-form-error-message>
        </div>

        <div class="col-md-3" *ngIf="strategyForm.controls['addition'] !== undefined">
            <label for="Zusatz">Zusatz*</label>
            <input
                class="form-control mb-2"
                id="Zusatz"
                formControlName="addition"
                type="text"
                [class.border-danger]="
                    strategyForm.controls['addition'].invalid && strategyForm.controls['addition'].dirty
                "
                readonly
            />
            <otb-form-error-message
                [show]="strategyForm.controls['addition'].invalid && strategyForm.controls['addition'].dirty"
            ></otb-form-error-message>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="form-group d-flex justify-content-between flex-md-row flex-column">
                <button
                    type="submit"
                    class="btn-success btn order-md-2 order-1 m-0 mb-3 mb-sm-0"
                    accesskey="w"
                    [title]="'dynamische_frontend_texte.verteilmechanismen.name_button_text' | translate"
                    [innerHTML]="
                        'dynamische_frontend_texte.verteilmechanismen.name_button_text' | translate | safeStyle
                    "
                ></button>

                <a
                    (click)="back()"
                    class="btn-secondary btn pull-left order-md-1 order-2"
                    [title]="'dynamische_frontend_texte.verteilmechanismen.zurueck' | translate"
                    [innerHTML]="'dynamische_frontend_texte.verteilmechanismen.zurueck' | translate | safeStyle"
                    accesskey="z"
                    tabindex="0"
                >
                </a>
            </div>
        </div>
    </div>
</form>
