<div class="row final-page">
    <div class="col-12">
        <h2 [innerHTML]="'dynamische_frontend_texte.danke.ueberschrift' | translate | safeStyle"></h2>
    </div>
    <div class="col-12">
        <p [innerHTML]="'dynamische_frontend_texte.danke.line1' | translate | safeStyle"></p>
    </div>
    <div class="col-12">
        <p [innerHTML]="'dynamische_frontend_texte.danke.line2' | translate | safeStyle"></p>
    </div>
</div>
