import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Signal,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { BreadcrumbService } from '../../services/breadcrumb/breadcrumb.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EnvironmentsEnum } from '../../enum/environments-enum';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../services/language/language.service';
import { WebcomponentInterfaceService } from '../../services/webcomponent-interface/webcomponent-interface.service';
import { ApiConfiguration } from '../../api/api-configuration';
import { faBookReader, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ApplicationSettingsResponse } from '../../api/models/application-settings-response';
import { ApplicationSettingsService as ApplicationSettingsServiceHttp } from '../../api/services/application-settings.service';
import { ApplicationSettingsService } from '../../services/application-settings/application-settings.service';
import { StateNamesEnum } from '../../enum/state-names-enum';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';
import { StateService } from '../../services/state/state.service';
import { ColorExtractorService } from '../skeleton-loader/color-extractor.service';
import getComputedStyle from '@popperjs/core/lib/dom-utils/getComputedStyle';
import { ApplicationLoadedService } from '../../services/application-loaded/application-loaded.service';

@Component({
    selector: 'otb-header',
    templateUrl: './header.component.html',
    styleUrls: [
        './header.component.scss',
        './header.component.mags-sop.scss',
        './header.component.justice.scss',
        './header.component.sh.scss',
        './header.component.education-check.scss',
        './header.component.finance.scss',
        './header.component.hmdj.scss'
    ]
})
export class HeaderComponent implements AfterViewChecked, OnDestroy, OnInit, AfterViewInit {
    @ViewChild('headerTop') headerTop: ElementRef;
    @ViewChild('langPickContainer') langPickContainer: ElementRef;
    @ViewChild('header') header: ElementRef;

    show: boolean = true;
    phoneIcon = faPhone;
    sub: Subscription = new Subscription();
    headerClasses: any;
    hasMultipleLanguages: boolean = false;
    headless: boolean;
    rootUrl = '';
    faPaperPlane = faPaperPlane;
    faBookReader = faBookReader;
    showEasyLanguagePage: boolean | undefined;

    errorLoadingApplicationSettings: Signal<boolean>;
    appLoaded: Signal<boolean>;
    constructor(
        private breadcrumbService: BreadcrumbService,
        private langService: LanguageService,
        private webcomponentInterfaceService: WebcomponentInterfaceService,
        private apiConfiguration: ApiConfiguration,
        private applicationSettingsServiceHttp: ApplicationSettingsServiceHttp,
        private applicationSettingsService: ApplicationSettingsService,
        private sessionStorageService: SessionStorageService,
        private stateService: StateService,
        private colorExtractorService: ColorExtractorService,
        private applicationLoadedService: ApplicationLoadedService
    ) {
        this.headerClasses = {
            'container p-0': this.hasSmallHeader()
        };

        this.rootUrl = this.apiConfiguration.rootUrl;

        const languages$ = this.langService.allLanguages.subscribe((languages) => {
            this.hasMultipleLanguages = languages.length > 1;
        });

        this.sub.add(languages$);

        this.errorLoadingApplicationSettings = this.applicationSettingsService.errorLoadingApplicationSettings;
        this.appLoaded = this.applicationLoadedService.applicationLoaded;
    }

    ngAfterViewInit(): void {
        this.extractColorFromHeader();
    }

    private extractColorFromHeader(): void {
        const style = getComputedStyle(this.header.nativeElement);
        this.colorExtractorService.headerColor.set(style.backgroundColor);
    }

    private initialize(): void {
        const subscription = this.applicationSettingsServiceHttp
            .getApiPublicApplicationSettings({ _locale: 'de' }) // langauge is not important here
            .subscribe((settings: ApplicationSettingsResponse) => {
                if (settings.data.show_easy_language_page) {
                    this.showEasyLanguagePage = settings.data.show_easy_language_page;
                }
            });
        this.sub.add(subscription);
    }

    private hasSmallHeader(): boolean {
        return environment.deploy_environment === 'sh'; // refactor when more than one customer has a small header
    }

    ngAfterViewChecked(): void {
        const MARGIN_TOP = 12;

        if (environment.deploy_environment === EnvironmentsEnum.MAGS_SOP) {
            this.sub = this.breadcrumbService.breadcrumbHeight.subscribe((height: number) => {
                if (height > 0) {
                    this.langPickContainer.nativeElement.style.top = height + MARGIN_TOP + 'px';
                    this.headerTop.nativeElement.style.height =
                        height + MARGIN_TOP + this.langPickContainer.nativeElement.offsetHeight + MARGIN_TOP + 'px';
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    ngOnInit(): void {
        this.initialize();
        this.headless = this.webcomponentInterfaceService.headlessToBoolean();
    }

    getRootUrl(): string {
        if (this.applicationSettingsService.serviceOnly) {
            return StateNamesEnum.SERVICE_ONLY;
        }

        return StateNamesEnum.COURT_AND_SERVICE;
    }

    clearStorage(): void {
        this.sessionStorageService.clearWorkflowAndBooking();
        this.stateService.init();
    }
}
