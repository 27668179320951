<div class="row">
    <otb-inner-breadcrumb
        *otbShowInEnv="['default', 'sh', 'education-check', 'finance', 'justice', 'hmdj']"
    ></otb-inner-breadcrumb>
    <otb-progress-bar></otb-progress-bar>
</div>

<div class="row">
    <div class="col-12">
        <h2 [innerHTML]="'dynamische_frontend_texte.persoenliche_daten.einfuehrung' | translate | safeStyle"></h2>
    </div>
</div>
<otb-dynamic-form *ngIf="personalDataFields.form_id !== 0" [personalDataFields]="personalDataFields"></otb-dynamic-form>
