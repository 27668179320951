import { Component, Input } from '@angular/core';
import { Breadcrumb } from '../model/breadcrumb';
import { StateService } from '../../../services/state/state.service';
import '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'otb-breadcrumb-item',
    templateUrl: './breadcrumb-item.component.html',
    styleUrls: [
        './breadcrumb-item.component.scss',
        './breadcrumb-item.component.mags-sop.scss',
        './breadcrumb-item.component.hmdj.scss'
    ]
})
export class BreadcrumbItemComponent {
    @Input() isLast: boolean = false;
    @Input() breadcrumb: Breadcrumb;

    faEdit = faEdit;

    constructor(private stateService: StateService) {}

    onBreadcrumbClick(): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.stateService[this.breadcrumb.backFunction]();

        if (this.breadcrumb.backFunction === 'backToCourtAndService') {
            this.stateService.init();
        }
    }
}
