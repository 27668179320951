import { DoBootstrap, Inject, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';

import { OtbRoutingModule } from './otb-routing.module';
import { OtbComponent } from './otb.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgencyServiceSelectionComponent } from './pages/agency-service-selection/agency-service-selection.component';
import { HeaderComponent } from './components/header/header.component';
import { DatePickComponent } from './pages/date-pick/date-pick.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { BreadcrumbItemComponent } from './components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ElementComponent } from './components/element/element.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { WarningComponent } from './components/warning/warning.component';
import { AssignmentStrategyComponent } from './pages/assignment-strategy/assignment-strategy.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SelectNumericComponent } from './components/form-builder/element/select-numeric/select-numeric.component';
import { ColorLegendComponent } from './components/pikaday/color-legend/color-legend.component';
import { TimePickSelectionComponent } from './components/pikaday/time-pick-second/time-pick-selection.component';
import { ReplaceDateTimePipe } from './pipes/calendar/timeslot.pipe';
import { ParticipantCountComponent } from './pages/participant-count/participant-count.component';
import { TimePickComponent } from './pages/time-pick/time-pick.component';
import { PersonalDataComponent } from './pages/personal-data/personal-data.component';
import { DynamicFormComponent } from './pages/personal-data/dynamic-form/dynamic-form.component';
import { FormFieldComponent } from './pages/personal-data/dynamic-form/form-field/form-field.component';
import { FormErrorMessageComponent } from './components/form-error-message/form-error-message.component';
import { EmailFieldComponent } from './pages/personal-data/dynamic-form/form-field/fields/email-field/email-field.component';
import { TextFieldComponent } from './pages/personal-data/dynamic-form/form-field/fields/text-field/text-field.component';
import { PhoneFieldComponent } from './pages/personal-data/dynamic-form/form-field/fields/phone-field/phone-field.component';
import { TextAreaFieldComponent } from './pages/personal-data/dynamic-form/form-field/fields/text-area-field/text-area-field.component';
import { NumericFieldComponent } from './pages/personal-data/dynamic-form/form-field/fields/numeric-field/numeric-field.component';
import { SelectFieldComponent } from './pages/personal-data/dynamic-form/form-field/fields/select-field/select-field.component';
import { NumericSelectFieldComponent } from './pages/personal-data/dynamic-form/form-field/fields/numeric-select-field/numeric-select-field.component';
import { DateFieldComponent } from './pages/personal-data/dynamic-form/form-field/fields/date-field/date-field.component';
import { CheckboxFieldComponent } from './pages/personal-data/dynamic-form/form-field/fields/checkbox-field/checkbox-field.component';
import { FileUploadFieldComponent } from './pages/personal-data/dynamic-form/form-field/fields/file-upload-field/file-upload-field.component';
import { CustomNgbDateParserFormatter } from './pages/personal-data/dynamic-form/form-field/fields/date-field/custom-ngbDateParserFormatter';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { StarPipe } from './services/star/star.pipe';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { DynamicLocaleId } from './services/language/DynamicLocaleId';
import { LanguageService } from './services/language/language.service';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { ParameterHashLocationStrategy } from './framework/common/location-strategy';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { CancelComponent } from './pages/cancel/cancel.component';
import { ReplacePipe } from './services/translate-api/replace.pipe';
import { SafeStylePipe } from './pipes/sanitizer/safe-style.pipe';
import { ShowInEnvDirective } from './directives/show-in-env.directive';
import { InnerBreadcrumbComponent } from './components/breadcrumb/inner-breadcrumb/inner-breadcrumb.component';
import { TopBreadcrumbComponent } from './components/breadcrumb/top-breadcrumb/top-breadcrumb.component';
import { FooterShComponent } from './components/footer/footer-sh/footer-sh.component';
import { TopInformationContainerComponent } from './components/top-information-container/top-information-container.component';
import { LoadingAnimationDotsComponent } from './components/loading-animation-dots/loading-animation-dots.component';
import { CustomDatepickerI18n } from './pages/personal-data/dynamic-form/form-field/fields/date-field/custom-datepicker-i18n';
import { HttpErrorInterceptor } from './services/http-error-interceptor/http-error-interceptor';
import { ErrorFlashMessageComponent } from './components/error-flash-message/error-flash-message.component';
import { HideInEnvDirective } from './directives/hide-in-env.directive';
import { ServiceOnlyComponent } from './pages/service-only/service-only.component';
import { ServiceOnlyTimePickComponent } from './components/pikaday/service-only-time-pick/service-only-time-pick.component';
import { AppointmentTypeComponent } from './pages/appointment-type/appointment-type.component';
import { AccessablityInfoComponent } from './pages/accessablity-info/accessablity-info.component';
import { EasyLanguageComponent } from './pages/easy-language/easy-language.component';
import { AgencySelectionComponent } from './components/agency-service-selection/agency-selection/agency-selection.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppointmentBookingLoadingComponent } from './components/appointment-booking-loading/appointment-booking-loading.component';
import { SkeletonLoaderMainComponent } from './components/skeleton-loader/main-site/skeleton-loader-main.component';
import { SkeletonLoaderHeaderComponent } from './components/skeleton-loader/header/skeleton-loader-header.component';

@NgModule({
    declarations: [
        OtbComponent,
        HeaderComponent,
        AgencyServiceSelectionComponent,
        DatePickComponent,
        FooterComponent,
        ProgressBarComponent,
        TopBreadcrumbComponent,
        BreadcrumbItemComponent,
        ElementComponent,
        WarningComponent,
        AssignmentStrategyComponent,
        CancelComponent,
        SelectNumericComponent,
        ColorLegendComponent,
        TimePickSelectionComponent,
        ReplaceDateTimePipe,
        TimePickComponent,
        ParticipantCountComponent,
        PersonalDataComponent,
        DynamicFormComponent,
        FormFieldComponent,
        FormErrorMessageComponent,
        EmailFieldComponent,
        TextFieldComponent,
        PhoneFieldComponent,
        TextAreaFieldComponent,
        NumericFieldComponent,
        SelectFieldComponent,
        NumericSelectFieldComponent,
        DateFieldComponent,
        CheckboxFieldComponent,
        FileUploadFieldComponent,
        ThankYouComponent,
        SummaryComponent,
        StarPipe,
        ReplacePipe,
        LanguagePickerComponent,
        ConfirmationComponent,
        SafeStylePipe,
        ShowInEnvDirective,
        HideInEnvDirective,
        InnerBreadcrumbComponent,
        FooterShComponent,
        TopInformationContainerComponent,
        LoadingAnimationDotsComponent,
        ServiceOnlyComponent,
        AppointmentBookingLoadingComponent,
        ServiceOnlyTimePickComponent,
        AppointmentTypeComponent,
        AccessablityInfoComponent,
        EasyLanguageComponent,
        AgencySelectionComponent,
        SkeletonLoaderMainComponent,
        SkeletonLoaderHeaderComponent
    ],
    imports: [
        BrowserModule,
        OtbRoutingModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgbModule,
        NgbDatepickerModule,
        FontAwesomeModule,
        TranslateModule.forRoot({}),
        ErrorFlashMessageComponent,
        NgxSkeletonLoaderModule.forRoot()
    ],
    providers: [
        {
            provide: NgbDateParserFormatter,
            useFactory: (): any => new CustomNgbDateParserFormatter()
        },
        {
            provide: LOCALE_ID,
            useClass: DynamicLocaleId,
            deps: [TranslateService, LanguageService]
        },
        {
            provide: LocationStrategy,
            useClass: ParameterHashLocationStrategy
        },
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        }
    ],
    exports: [ShowInEnvDirective]
})
export class OtbModule implements DoBootstrap {
    constructor(
        injector: Injector,
        @Inject(LOCALE_ID) public locale: string,
        @Inject(DOCUMENT) private document: Document
    ) {
        const el = createCustomElement(ElementComponent, { injector });
        customElements.define('otb-component', el);
        this.document.documentElement.lang = locale.toLowerCase();
    }

    // Angular erwartet hier zwingend diese Methode oder den bootstrap Eintrag im Array weiter oben.
    // Da Angular Elements keine Bootstrap einträge benötigen bleibt diese leer.
    // Falls die Methode entfernt wird kommt es beim builden zu Fehlern.
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngDoBootstrap(): void {}
}
